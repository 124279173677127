<template lang="html">
    <div class="ChatPageV2--Chat">
        <div class="ChatVideo">
            <h1>{{ title }}</h1>
            <i v-if="secretIcon" class="fas fa-user-secret"></i>
            <div class="ChatVideo--Messages" ref="messages" @scroll="handleScroll">
                <div class="ChatVideo--Spinner-container" v-if="isFetchingOldMessages">
                    <Spinner dark />
                </div>
                <div v-for="(m, index) in localMessages" :key="m.id">
                    <div class="chatMessage" :class="{
                        'chatMessage--mod': m.isModerator || isUser,
                        'chatMessage--user': (isUser && name !== m.nickname && !m.isModerator) || (!m.isModerator && isClient)
                    }">
                        <div class="chatMessage__Message">
                            <div>
                                <p v-if="m.isModerator">
                                    {{ $t('role_moderator') }}, {{ $filters.formatDateFull(m.creationDate) }}
                                </p>
                                <p v-else-if="!m.isModerator && !m.nickname">
                                    {{ getNicknameById(m.ownerId) }}, {{ $filters.formatDateFull(m.creationDate) }}
                                </p>
                                <p v-else-if="!m.isModerator && m.nickname">
                                    {{ m.nickname }}, {{ $filters.formatDateFull(m.creationDate) }}
                                </p>
                                <p v-if="!m.image && !m.video && !m.file" class="chatMessage__Text">{{ m.message }}</p>
                                <div class="chatMessage__Media" v-if="m.file">
                                    <a :href="m.file" v-if="m.file">
                                        <i class="fas fa-file-download"></i>{{ $t('comment_download_subject') }}
                                    </a>
                                </div>
                                <MediaViewer v-if="m.image" :images="$filters.formatMedia([m.image])" />
                                <MediaViewer v-if="m.video" :videos="$filters.formatVideoMedia([m.video])" />
                            </div>
                            <div @click="toggleTooltip(m)" class="tooltipContainer"
                                v-if="isModerator || isCommunityMainAdmin">
                                <i class="fas fa-ellipsis-v"></i>
                                <div class="tooltipMenu" :class="{ 'tooltipMenu--first': index === 0 }"
                                    v-if="m.showTooltip" @click="handleClick(m)">
                                    <p>Eliminar</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ChatVideo--Media" v-if="fileUploadPath && !disableActions">
                <!-- Manejo de Dropzones para imagen, archivo y video -->
                <div v-if="!showChatVideoUploader && !showChatFileUploader">
                    <span class="ChatVideo--MediaIcon" @click="showChatImageUploader = !showChatImageUploader">
                        <i class="fa fa-image"></i>
                    </span>
                    <transition name="fade">
                        <Dropzone v-if="showChatImageUploader" :uploadUrl="fileUploadPath"
                            :uploadHeaders="uploadHeaders" :uploadText="$t('comment_upload_image')"
                            acceptedFiles="image/jpg,image/jpeg,image/png" :maxFiles="1" @file-uploaded="handleAddFile"
                            @file-removed="handleRemoveFile" />
                    </transition>
                </div>
                <div v-if="!showChatVideoUploader && !showChatImageUploader">
                    <span class="ChatVideo--MediaIcon" @click="showChatFileUploader = !showChatFileUploader">
                        <i class="fa fa-file"></i>
                    </span>
                    <transition name="fade">
                        <Dropzone v-if="showChatFileUploader" :uploadUrl="fileUploadPath" :uploadHeaders="uploadHeaders"
                            :uploadText="$t('comment_upload_file')" :maxFiles="1" @file-uploaded="handleAddFile"
                            @file-removed="handleRemoveFile" />
                    </transition>
                </div>
                <div v-if="!showChatFileUploader && !showChatImageUploader">
                    <span class="ChatVideo--MediaIcon" @click="showChatVideoUploader = !showChatVideoUploader">
                        <i class="fa fa-video"></i>
                    </span>
                    <transition name="fade">
                        <Dropzone v-if="showChatVideoUploader" :uploadUrl="videoUploadPath"
                            :uploadHeaders="uploadHeaders" :uploadText="$t('comment_upload_video')" :maxFiles="1"
                            acceptedFiles="video/*" @file-uploaded="handleAddFile" @file-removed="handleRemoveFile" />
                    </transition>
                </div>
            </div>
            <form class="ChatVideo--input" v-show="!disableActions" @submit.prevent="videochatSendMessage">
                <textarea :disabled="disabled || messageFile !== null" v-model="message"
                    :placeholder="messageFile ? $t('chat_image_message_info') : $t('videochat_chat_input')"></textarea>
                <button type="submit" :disabled="disabled">
                    <i class="fas fa-paper-plane"></i>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ApiService from '../../services/api.service';
import store from '@/store';
import DOMPurify from 'dompurify';
import Dropzone from '~/components/Dropzone';
import MediaViewer from '~/components/MediaViewer';
import Spinner from '~/components/Spinner';
import Swal from 'sweetalert2';

export default {
    name: 'Chat',
    components: {
        Dropzone,
        MediaViewer,
        Spinner,
    },
    props: {
        isClient: { type: Boolean, default: false },
        isUser: { type: Boolean, default: false },
        isModerator: { type: Boolean, default: false },
        name: { type: String, default: "" },
        messages: { type: Array },
        title: { type: String },
        user: { type: Object },
        disabled: { type: Boolean, default: false },
        disableActions: { type: Boolean, default: false },
        secretIcon: { type: Boolean, default: false },
        members: Object,
        moderatorUser: { type: Boolean, default: false },
        isWatFocus: { type: Boolean, default: false },
        isFetchingOldMessages: { type: Boolean, default: false },
        uploadToken: { type: String, default: "" }
    },
    computed: {
        ...mapGetters(['isCommunityMainAdmin']),
    },
    data() {
        return {
            fileUploadPath: process.env.VUE_APP_API_URL + (this.isWatFocus ? '/file' : '/experienceLabs/file'),
            videoUploadPath: process.env.VUE_APP_API_URL + (this.isWatFocus ? '/video' : '/experienceLabs/video'),
            uploadHeaders: ApiService.getConfig().headers,
            showChatFileUploader: false,
            showChatImageUploader: false,
            showChatVideoUploader: false,
            message: "",
            messageFile: null,
            selectedMessages: [],
            localMessages: JSON.parse(JSON.stringify(this.messages)),
            hasInitialLoad: false,
        };
    },
    watch: {
        messages: {
            handler(newMessages) {
                if (newMessages) {
                    this.localMessages = JSON.parse(JSON.stringify(newMessages));
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            enlargePic: 'enlargePic',
        }),
        handleScroll(e) {
            const container = e.target;
            if (!this.hasInitialLoad) {
                this.hasInitialLoad = true;
                return;
            }
            if (container.scrollTop === 0 && !this.isFetchingOldMessages) {
                const previousHeight = container.scrollHeight;
                const previousScrollTop = container.scrollTop;
                this.$emit('fetch-older-messages', { previousHeight, previousScrollTop });
            }
        },
        async videochatSendMessage() {
            const type = this.getUploadType();
            const sanitizedMessage = DOMPurify.sanitize(this.message);
            const data = { message: sanitizedMessage };
            if (this.messageFile !== null) {
                data[type] = this.messageFile;
                data.message = type;
            }
            if (data.message.trim().length) {
                this.$emit('send-message', data);
            }
            this.resetMessageState();
        },
        getUploadType() {
            if (this.showChatImageUploader) return 'image';
            if (this.showChatFileUploader) return 'file';
            if (this.showChatVideoUploader) return 'video';
            return '';
        },
        resetMessageState() {
            this.message = '';
            this.messageFile = null;
            this.showChatImageUploader = false;
            this.showChatFileUploader = false;
            this.showChatVideoUploader = false;
        },
        handleAddFile(file, response) {
            if (!this.messageFile) this.messageFile = response.path;
            this.videochatSendMessage();
        },
        handleRemoveFile(file) {
            if (this.messageFile && this.messageFile.match(file.name)) this.messageFile = null;
        },
        adjustMessagesScroll() {
            // Usamos scrollTo en vez de jQuery animate para mayor eficiencia
            if (this.$refs.messages) {
                this.$refs.messages.scrollTo({ top: this.$refs.messages.scrollHeight, behavior: 'smooth' });
            }
        },
        getNicknameById(memberId) {
            if (!this.isClient && Object.keys(this.members).length < 1) {
                return this.$t('chat_user_participant');
            } else if (memberId !== null && memberId !== undefined) {
                for (const key in this.members) {
                    if (this.members.hasOwnProperty(key)) {
                        const member = this.members[key];
                        if (member.identifier === memberId) {
                            return (member.nickname == null || (member.nickname && member.nickname.includes("-" + member.identifier)))
                                ? member.name : member.nickname;
                        }
                    }
                }
            }
            return null;
        },
        toggleTooltip(message) {
            this.localMessages.forEach(m => {
                if (m.identifier !== message.identifier) m.showTooltip = false;
            });
            message.showTooltip = !message.showTooltip;
            setTimeout(() => {
                message.showTooltip = false;
            }, 1500);
        },
        async handleClick(message) {
            if (this.isModerator || this.isCommunityMainAdmin) {
                await this.deleteSelectedMessages(message);
            }
        },
        async deleteSelectedMessages(message) {
            const { isConfirmed } = await Swal.fire({
                title: '¿Estás seguro?',
                text: '¿Estás seguro de que deseas eliminar este mensaje?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });
            if (isConfirmed) {
                this.selectedMessages.push(message.identifier);
                try {
                    if (this.isWatFocus) {
                        await store.dispatch(WAT_FOCUS_DELETE_CHAT_MESSAGE, { messageIds: this.selectedMessages });
                    } else {
                        this.$emit('delete-chat-message', this.selectedMessages);
                    }
                    this.localMessages = this.localMessages.filter(m => m.identifier !== message.identifier);
                    this.clearSelectedMessages();
                } catch (error) {
                    console.error(error);
                }
            } else {
                this.clearSelectedMessages();
            }
        },
        clearSelectedMessages() {
            this.selectedMessages = [];
        },
    },
    created() {
        this.uploadHeaders['authToken'] = this.uploadToken;
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$refs.messages) {
                this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
            }
        });
    }
};
</script>

<style scoped lang="scss">
.ChatVideo {
    background: #F5F5F5;
    border: 1px solid #D3D3D3;
    padding: 15px;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 22px;
        text-align: center;
        color: #3E3E3E;
        font-weight: normal;
        margin-bottom: 10px;
    }

    &--Messages {
        position:relative;
        flex-grow: 1;
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;

        &::-webkit-scrollbar-track {
            background-color: #e6e6e6;
        }

        &::-webkit-scrollbar {
            background-color: #8134ff;
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: var(--primary-color);
        }
    }
    &--Spinner-container{
        position:absolute;
        inset:0;
        background-color: white;
        display:flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    &--Media {
        flex-grow: 0;
        display: flex;
    }

    &--MediaIcon {
        background-color: #F2F2F2;
        margin: 5px 0;
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--input {
        flex-grow: 0;
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        margin: 10px 0;
        font-family: Roboto Condensed;
        gap:1rem;

        button {
            border: none;
            background: transparent;
            outline: none;
        }

        textarea {
            height: 100%;
            max-height: 150px;
            border: none;
            width: 100%;
            outline: none;
            resize: none;
            padding: 10px 5px;
            background: white;
            field-sizing: content;
            transition: all .3s ease;
            &::-webkit-scrollbar-track {
                background-color: #e6e6e6;
            }

            &::-webkit-scrollbar {
                background-color: #8134ff;
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: var(--primary-color);
            }
        }

        i {
            color: var(--accent-color);
            font-size: 16px;
        }
    }
}

.chatMessage {
    position: relative;
    width: 100%;
    display: flex;
    min-height: 64px;
    margin: 10px 0;
.chatMessage__Message {
        background: #fafafa; // Fondo específico para el mensaje del usuario
    }
    &--mod {
        justify-content: flex-end; // Mensaje del moderador alineado a la derecha

        .chatMessage__Message {
            background: #eeeeee; // Fondo específico para el mensaje del moderador

            p:first-child {
                text-align: right;
            }

            &:after {
                content: "";
                position: absolute;
                top: 17px;
                right: -16px;
                border-width: 8px;
                border-style: solid;
                border-color: transparent transparent transparent #eeeeee;
            }
        }
    }

    &--user {
        justify-content: flex-start; // Mensaje del usuario alineado a la izquierda

        .chatMessage__Message {
            background: #fafafa!important; // Fondo específico para el mensaje del usuario

            p:first-child {
                text-align: left;
            }

            &:after {
                content: "";
                position: absolute;
                top: 17px;
                left: -16px;
                border-width: 8px;
                border-style: solid;
                border-color: transparent #fafafa transparent transparent!important;
            }
        }
    }

    &__Message {
        display: flex;
        margin: 10px;
        font-size: 17px;
        color: #3E3E3E;
        max-width: 65%;
        padding: 10px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease;
        border-radius: 10px;

        p:first-child {
            font-size: 13px;
            color: #3E3E3E;
            opacity: 0.5;
        }
    }


    &__Text {
        word-break: break-word;
    }

    &__Media {
        width: 100%;

        a {
            color: #3e3e3e;
        }

        i {
            margin-right: 5px;
        }

        img,
        video {
            width: 100%;
            border: 1px solid #D3D3D3;
            cursor: pointer;
        }
    }
}

.fa-user-secret {
    position: absolute;
    top: -8px;
    font-size: 100px;
    transform: rotate(-45deg);
    opacity: 0.1;
    left: 0px;
}

.tooltipContainer {
    padding-left: 10px;
    position: relative;
    opacity: 0.2;
    transition: all .3s ease;
    z-index: 999;
}

.chatMessage__Message:hover .tooltipContainer {
    opacity: 1;
}

.tooltipMenu {
    position: absolute;
    bottom: calc(100% + 6px);
    right: -3px;
    background: white;
    border: 1px solid #D3D3D3;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all 0.3s ease;
    z-index: 998;

    &::before {
        content: "";
        position: absolute;
        top: 100%;
        right: 0;
        border-width: 5px;
        border-style: solid;
        border-color: #D3D3D3 transparent transparent transparent;
    }

    p {
        color: black !important;
    }

    &:hover {
        cursor: pointer;
        background: #F5F5F5;
        border: 1px solid #D3D3D3;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0);

        p {
            color: #3E3E3E;
        }
    }
}

.tooltipMenu--first {
    bottom: auto;
    top: calc(100% - 6px);
    transform: translateY(0);
}

.tooltipMenu--first::before {
    top: auto;
    bottom: 100%;
    border-color: transparent transparent #D3D3D3 transparent;
}
</style>
